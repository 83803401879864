<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
withDefaults(
  defineProps<{
    label?: string;
    error?: string;
    helpText?: string;
    allowHtml?: boolean;
    selectable?: boolean; 
  }>(),
  {
    label: "",
    error: "",
    helpText: "",
    allowHtml: false,
    selectable: true,
  }
);

const slots = useSlots();
</script>

<template>
  <div class="flex flex-col">
    <label 
      class="flex items-start"
      :class="{ 
        'cursor-pointer': selectable,
        'text-maas-typography-text-grey-light line-through': !selectable 
      }"
    >
      <slot />
      <div v-if="allowHtml == false" class="ml-4 mt-[2px]">
        <span v-if="label">
          {{ label }}
        </span>
        <div v-if="helpText" class="text-maas-typography-text-grey-medium mt-1 text-small">
          {{ helpText }}
        </div>
      </div>

      <div v-if="allowHtml || slots.label || slots.helpText" class="ml-4">
        <span v-if="label" v-html="label"></span>
        <span v-else><slot name="label" /></span>
        <div v-if="helpText" class="text-maas-typography-text-grey-medium text-left text-small " v-html="helpText"></div>
        <div v-else class="text-maas-typography-text-grey-medium text-left text-small "><slot name="helpText" /></div>
      </div>

    </label>

    <div v-if="error" class="mt-3 mb-3">
        <span class="text-left text-red-700">{{ error }}</span>
      </div>
  </div>
</template>
